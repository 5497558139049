.subcategory-list-page {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.breadcrumb {
  margin-bottom: 20px;
  font-size: 16px;
  color: #6c757d;  /* Subtle color for breadcrumb text */
}

.arrow-icon {
  margin-left: 5px;
  color: #888;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.show-record-header {
  flex-grow: 1;
  text-align: left;
}

.show-record-header h6 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin: 0;
}

.right-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-container {
  position: relative;
  flex-grow: 1;
}

.search-input {
  padding-right: 30px;
  padding-left: 12px;
  font-size: 14px;
  width: 100%;
  max-width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #6200ea;  /* Highlight border on focus */
}

.search-icon-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
}

.search-icon-btn:hover svg {
  color: #6200ea;  /* Change icon color on hover */
}

.add-btn {
  padding: 10px 15px;
  background-color: #6200ea;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-btn:hover {
  background-color: #3700b3;
}

.subcategory-table-container {
  margin-top: 20px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #6200ea;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6200ea;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.subcategory-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.subcategory-table th,
.subcategory-table td {
  padding: 14px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #333;
}

.subcategory-table th {
  background-color: #f4f4f9;
  font-weight: 600;
}

.subcategory-row-container {
  display: flex;
  flex-wrap: wrap;  /* Allow subcategories to wrap if necessary */
}

.subcategory-row {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.delete-btn {
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 5px;
}

.delete-btn:hover {
  color: #c20000;
}

.subcategory-row span {
  margin-right: 5px;  /* Add some space between subcategory name and comma */
}

.subcategory-row-container span {
  margin-right: 5px;
}


.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  list-style: none;
  display: flex;
  gap: 10px;
}

.pagination a {
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination a:hover {
  background-color: #6200ea;
  color: white;
}

.pagination .active a {
  background-color: #6200ea;
  color: white;
}

.pagination .disabled a {
  cursor: not-allowed;
  opacity: 0.6;
}
