/* smallCategoryList.css */
.list-page {
    font-family: "Arial", sans-serif;
    padding: 20px;
  }
  
  .content {
    margin-bottom: 20px;
    font-size: 16px;
    color: #6c757d;
  }
  
  .breadcrumb {
    font-size: 18px;
    font-weight: 500;
  }
  
  .arrow-icon {
    margin-left: 5px;
    color: #888;
  }
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .page-header .info {
    font-size: 16px;
    color: #6c757d;
  }
  
  .page-header .search-box {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  
  .search-input-wrapper {
    position: relative;
  }
  
  .search-input-wrapper input {
    padding: 10px 40px 10px 10px; /* Add padding to make room for the icon */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }
  
  .page-header .add-button {
    padding: 10px 20px;
    background-color: #6200ea;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .page-header .add-button:hover {
    background-color: #3700b3;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #6200ea;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #6200ea;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .data-table th,
  .data-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .data-table th {
    background-color: #f4f4f4;
  }
  
  .data-table .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .data-table .action-buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .data-table .action-buttons button:hover {
    opacity: 0.7;
  }
  
  .delete-button {
    color: #fff;
  }
  
  .edit-button {
    color: #fff;
  }
  
  .error-text {
    color: red;
    font-weight: bold;
  }
  