/* General Page Styles */
.add-subcategory-page-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.content {
  margin-bottom: 20px;
}

.breadcrumb {
  font-size: 16px;
  color: #6c757d;
}

.arrow-icon {
  margin-left: 5px;
  color: #888;
}

.add-subcategory-form-wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.add-subcategory-heading {
  font-size: 24px;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

/* Form Styles */
.add-subcategory-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-subcategory-form-group {
  display: flex;
  flex-direction: column;
}

.add-subcategory-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.add-subcategory-select,
.add-subcategory-input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

.add-subcategory-select:focus,
.add-subcategory-input:focus {
  border-color: #6200ea;
}

.add-subcategory-input {
  width: 100%;
}

/* Button Styles */
.add-subcategory-submit-btn {
  padding: 10px 20px;
  background-color: #6200ea;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-subcategory-submit-btn:disabled {
  background-color: #d1c8f1;
  cursor: not-allowed;
}

.add-subcategory-submit-btn:hover:enabled {
  background-color: #3700b3;
}

/* Loader Styles */
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6200ea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error Styles */
.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.error-message span {
  font-size: 16px;
}
