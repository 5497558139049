/* Main container */
.order-details-page {
  width: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  padding: 20px;
}

/* Order Header */
.order-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .order-id {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .order-id span {
    font-size: 16px;
    color: #32b350; /* Green color for paid status */
    background-color: #c1f8ce; /* Light green background for paid status */
    padding: 5px 8px;
    font-weight: 100;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .order-status {
    font-size: 14px;
    font-weight: normal;
    color: #d10000; /* Light blue color for fulfilled status */
  }
  
  .order-date {
    font-size: 14px;
    color: #6c757d; /* Gray color for the date */
    margin: 5px 0 10px;
  }
  
  .order-status.pa {
    background-color: #d1ecf1; /* Light blue background for fulfilled status */
    padding: 3px 10px;
    border-radius: 15px;
    display: inline-block;
  }
  

/* Main content container */
.order-details-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

/* Section styles */
.order-details-section {
  width: 48%;
}

/* Left section (product details) */
.product-details {
  border: 1px solid #ddd; /* Light gray border */
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  background-color: #fff; /* White background */
}

.product-details h3 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #000; /* Black text for section titles */
}
/* Table styles */
/* General table styling */
.product-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: "Arial", sans-serif;
}

.product-info-table th,
.product-info-table td {
  padding: 15px;
  text-align: left;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.product-info-table th {
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

.product-info-table td {
  background-color: #fff;
  color: #333;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

/* Product name and image styling */
.product-item {
  display: flex;
  align-items: center;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;
}

.product-name {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-right: 10px;
}

.product-code,
.product-size {
  font-size: 12px;
  color: #888;
}

/* Price, quantity, and total columns styling */
.product-price,
.product-quantity,
.product-total {
  font-size: 16px;
  text-align: center;
  color: #333;
}

.product-price {
  font-weight: bold;
}

.product-quantity {
  color: #666;
}

.product-total {
  font-weight: bold;
  color: #2d2d2d;
}

/* Table footer (if applicable) */
.product-info-table tr:last-child td {
  border-bottom: none;
}

/* Right section (shipping and payment details) */
.sp-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 20px;
}

.shipping-info,
.payment-summary,
.payment-details {
  width: 50%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.shipping-method,
.shipping-fee,
.subtotal,
.delivery-fee {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.shipping-method {
  font-weight: normal;
}

.shipping-fee {
  font-weight: bold;
}

.payment-summary .total-amount {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.total-amount h4 {
  color: #000;
  font-size: 20px;
}

.payment-summary .subtotal,
.payment-summary .delivery-fee {
  font-weight: normal;
}

.payment-summary .subtotal,
.payment-summary .delivery-fee,
.payment-summary .total-amount {
  margin-bottom: 12px;
}

.payment-summary {
  border-left: 2px solid #ddd;
  padding-left: 20px;
}

.payment-details {
  width: 50%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.payment-details p {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.payment-details .payment-method {
  font-weight: normal;
}

.payment-details .selected-bank {
  font-weight: bold;
}

/* Right section (customer details) */
.customer-details {
  border: 1px solid #ddd; /* Light gray border */
  padding: 20px;
  width: 100%;
  max-width: 350px; /* Increased max-width for better content spacing */
  border-radius: 8px;
  background-color: #fff; /* White background */
}

.customer-details h3 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #000; /* Black text for section titles */
}

.customer-details p {
  margin: 5px 0;
  color: #000; /* Black text for paragraphs */
  font-size: 14px; /* Adjust font size for readability */
}

.customer-details .customer-name {
  font-weight: bold;
  color: #000; /* Black text for customer name */
  font-size: 16px; /* Slightly larger font for name */
}

.customer-details .customer-contact {
  display: flex;
  flex-direction: column;
}

.customer-details .customer-contact p {
  font-size: 14px;
  color: #555; /* Lighter color for contact info */
}

.customer-details .address-info,
.customer-details .billing-address-info {
  margin-top: 20px;
}

.address-info p,
.billing-address-info p {
  font-size: 14px;
  color: #555; /* Slightly lighter color for address text */
}

.customer-details .address-info {
  border-top: 1px solid #ddd; /* Border to separate address sections */
  padding-top: 10px;
  margin-top: 15px;
}

.customer-details .billing-address-info {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 15px;
}

.customer-details .section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333; /* Dark gray for titles */
}

.customer-details .section-title + p {
  margin-top: 5px;
}

.customer-details .customer-info {
  margin-bottom: 20px;
}

.customer-details .customer-info p {
  font-size: 14px;
  color: #555; /* Lighter color for contact info */
}

.customer-details .customer-info .customer-email,
.customer-details .customer-info .customer-mobile {
  display: inline-block;
  margin-right: 10px;
}

.customer-details .customer-name {
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Darker text color for the name */
  margin-bottom: 10px; /* Space between name and other details */
}

.customer-details .customer-contact {
  margin-top: 10px; /* Space between customer info and contact */
}

.customer-details .customer-contact p {
  font-size: 14px;
  color: #555; /* Slightly lighter color for contact details */
}

.customer-details .customer-info p,
.customer-details .address p,
.customer-details .billing-address-info p {
  font-size: 14px;
  color: #555; /* Text color for all paragraph items */
}

/* Error message */
.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

/* No order message */
.no-order-message {
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
