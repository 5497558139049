.add-small-category-container {
  font-family: "Arial", sans-serif;
  padding: 20px;
}

/* Content */
.content {
  margin-bottom: 20px;
  font-size: 16px;
  color: #6c757d;
}

.breadcrumb {
  font-size: 18px;
  font-weight: 500;
}

.breadcrumb .arrow-icon {
  margin-left: 5px;
  color: #888;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #6200ea;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6200ea;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Form Styles */
.add-small-category-form-container {
  max-width: 500px;
  margin: 0 auto;
}

.add-small-category-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.add-small-category-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.add-small-category-form-group {
  margin-bottom: 20px;
}

.add-small-category-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.add-small-category-select,
.add-small-category-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-small-category-submit-button {
  width: 100%;
  padding: 12px;
  background-color: #6200ea;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-small-category-submit-button:hover {
  background-color: #4b00b4;
}
