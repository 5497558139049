/* Center the content vertically and horizontally */
.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    font-family: Arial, sans-serif;
    text-align: center;
}

/* Large and bold for the error code */
.not-found h1 {
    font-size: 8rem;
    color: #e63946; /* Deep red for error code to make it stand out */
    margin: 0;
}

/* Smaller header for the message */
.not-found h2 {
    font-size: 2rem;
    color: #333; /* Darker shade of black for contrast */
    margin-top: 0.5rem;
}

/* Subtext to guide the user */
.not-found p {
    font-size: 1rem;
    color: #555; /* Gray for subtle emphasis */
    margin: 1rem 0;
}

/* Style for the back button */
.back-home-btn {
    list-style: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff; /* White text */
    background-color: #000; /* Black background for the button */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.back-home-btn:hover {
    background-color: #333; /* Slightly lighter on hover for effect */
}
