/* General Reset */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s, color 0.3s;
  overflow-x: hidden;
}

/* Code Blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Light Theme */
body.light-theme {
  background-color: #f5f5f5; /* Light background */
  color: #333; /* Dark text */

}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Inter, sans-serif;
  color: #111;
  margin: 0;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.light-theme header,
.light-theme .sidebar-container,
.light-theme .dashboard-layout {
  background-color: #ffffff; /* Light elements background */
  color: #333; /* Dark text */
}

.light-theme a {
  color: #007bff; /* Link color */
}

.light-theme a:hover {
  color: #0056b3; /* Hovered link color */
}

/* Dark Theme */
body.dark-theme {
  background-color: #121212; /* Dark background */
  color: #f5f5f5; /* Light text */
}

.dark-theme header,
.dark-theme .sidebar-container,
.dark-theme .dashboard-layout {
  background-color: #1f1f1f; /* Dark elements background */
  color: #f5f5f5; /* Light text */
}

.dark-theme a {
  color: #bb86fc; /* Accent link color for dark theme */
}

.dark-theme a:hover {
  color: #3700b3; /* Hovered link color for dark theme */
}

/* Navbar Styles */
header {
  transition: background-color 0.3s, color 0.3s;
}

header.light {
  background-color: #ffffff;
  color: #333;
}

header.dark {
  background-color: #1f1f1f;
  color: #f5f5f5;
}

/* Sidebar Styles */
.sidebar-container {
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-container.light {
  background-color: #ffffff;
  color: #333;
}

.sidebar-container.dark {
  background-color: #1f1f1f;
  color: #f5f5f5;
}

.sidebar-container a {
  text-decoration: none;
  color: inherit;
}

.sidebar-container a:hover {
  text-decoration: underline;
}

/* Dashboard Styles */
.dashboard-layout {
  transition: background-color 0.3s, color 0.3s;
}

.dashboard-layout.light {
  background-color: #f8f9fa;
}

.dashboard-layout.dark {
  background-color: #181818;
}

/* Buttons */
button {
  transition: background-color 0.3s, color 0.3s;
}

button.light {
  background-color: #007bff;
  color: #ffffff;
}

button.dark {
  background-color: #6200ee;
  color: #ffffff;
}

button:hover.light {
  background-color: #0056b3;
}

button:hover.dark {
  background-color: #3700b3;
}
