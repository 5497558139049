/* Reset Password Page Styles */
.reset-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.reset-password-container {
  background-color: #fff; /* Light background for the form */
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.reset-password-container .reset-password-title {
  font-size: 28px;
  color: #333; /* Dark text color */
  margin-bottom: 30px;
}

.reset-password-field {
  position: relative;
  margin-bottom: 25px;
}

.reset-password-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #333; /* Dark icon color */
  font-size: 20px;
}

.reset-password-input {
  width: 100%;
  padding: 12px 12px 12px 36px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff; /* Light input background */
  color: #333; /* Dark text inside input */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.reset-password-input:focus {
  border-color: #f5bc00; /* Hover color as focus border */
  box-shadow: 0 0 6px rgba(245, 188, 0, 0.4);
  outline: none;
}

.reset-password-label {
  position: absolute;
  top: 12px;
  left: 40px;
  font-size: 14px;
  color: #333; /* Dark label color */
  transition: all 0.3s ease;
}

.reset-password-input:focus + .reset-password-label,
.reset-password-input:not(:placeholder-shown) + .reset-password-label {
  top: -10px;
  left: 35px;
  font-size: 12px;
  color: #f5bc00; /* Hover color on focus */
  background-color: #fff; /* Light background for label */
  padding: 0 5px;
  border-radius: 4px;
}

.reset-password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #333;
  font-size: 20px;
  transition: color 0.3s ease;
}

.reset-password-toggle:hover {
  color: #f5bc00; /* Hover color for toggle */
}

.reset-password-button {
  background-color: #f5bc00; /* Hover color as background */
  color: #fff;
  padding: 14px 20px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.reset-password-button:hover {
  background-color: #e0a500; /* Darker shade on hover */
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

/* Media Queries for Smaller Screens */
@media (max-width: 1024px) and (min-width: 250px) {
  .reset-password-container {
    width: 90%; /* 90% of screen width */
    max-width: none; /* Remove max-width */
    padding: 20px; /* Adjust padding */
  }

  .reset-password-title {
    font-size: 24px; /* Adjust title font size */
  }

  .reset-password-input {
    font-size: 14px; /* Smaller input text */
    padding: 10px 12px 10px 30px; /* Adjust padding */
  }

  .reset-password-label {
    font-size: 12px; /* Smaller label font size */
  }

  .reset-password-button {
    font-size: 14px; /* Smaller button text */
    padding: 12px 18px; /* Adjust button padding */
  }

  .reset-password-icon {
    font-size: 18px; /* Smaller icon size */
  }

  .reset-password-toggle {
    font-size: 18px; /* Smaller toggle icon */
  }
}

@media (max-width: 767px) {
  /* Further adjustments for very small screens */
  .reset-password-container {
    width: 95%; /* Increase width for very small screens */
    padding: 15px;
  }

  .reset-password-title {
    font-size: 20px; /* Adjust title font size */
  }

  .reset-password-input {
    font-size: 14px;
    padding: 8px 12px 8px 30px;
  }

  .reset-password-label {
    font-size: 12px;
  }

  .reset-password-button {
    font-size: 14px;
    padding: 12px 16px;
  }

  .reset-password-icon {
    font-size: 16px; /* Adjust icon size */
  }

  .reset-password-toggle {
    font-size: 16px; /* Adjust toggle icon size */
  }
}