.basic-info-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.profile-image-container {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-details {
  flex-grow: 1;
}

.user-name {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.user-email, .user-phone {
  font-size: 16px;
  color: #555;
  margin: 8px 0;
}

.user-email strong, .user-phone strong {
  font-weight: 700;
  color: #000;
}

.social-links {
  margin-top: 16px;
}

.social-icon {
  margin-right: 12px;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #007bff; /* Hover color */
}

.social-icon:active {
  color: #ffcc00; /* Active color */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .basic-info-container {
    padding: 15px;
    gap: 15px;
    flex-direction: column;
    align-items: center;
  }

  .profile-image-container {
    width: 100px;
    height: 100px;
  }

  .user-name {
    font-size: 22px;
  }

  .user-email, .user-phone {
    font-size: 14px;
  }

  .social-icon {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .basic-info-container {
    padding: 10px;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .profile-image-container {
    width: 80px;
    height: 80px;
  }

  .user-name {
    font-size: 20px;
  }

  .user-email, .user-phone {
    font-size: 14px;
  }

  .social-icon {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .basic-info-container {
    padding: 8px;
    gap: 8px;
  }

  .profile-image-container {
    width: 70px;
    height: 70px;
  }

  .user-name {
    font-size: 18px;
  }

  .user-email, .user-phone {
    font-size: 12px;
  }

  .social-icon {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .basic-info-container {
    padding: 5px;
    gap: 5px;
  }

  .profile-image-container {
    width: 60px;
    height: 60px;
  }

  .user-name {
    font-size: 16px;
  }

  .user-email, .user-phone {
    font-size: 10px;
  }

  .social-icon {
    font-size: 14px;
  }
}
