/* Edit Slider Page Styling */

.edit-slider-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.content {
  margin-bottom: 20px;
}

.breadcrumb {
  font-size: 16px;
  color: #5f6368;
}

.arrow-icon {
  margin-left: 10px;
  font-size: 20px;
}

/* Card Container */
.edit-slider-card {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

/* Heading */
.edit-slider-card h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Error Message */
.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Input Group */
.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.input-group input:focus {
  outline: none;
  border-color: #6200ea;
}

/* Image Upload */
.image-upload {
  margin-bottom: 20px;
}

.upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ddd;
  border-radius: 8px;
  padding: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-box:hover {
  background-color: #f9f9f9;
}

.upload-box p {
  color: #333;
  font-size: 16px;
}

.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider-image {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.remove-image:hover {
  background-color: #d32f2f;
}

/* File Input */
.file-input {
  display: none;
}

/* Buttons */
.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button {
  background-color: #6200ea;
  color: white;
  border: none;
}

.save-button:hover {
  background-color: #3700b3;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

/* Loading Spinner */
.loading {
  font-size: 16px;
  color: #6200ea;
  text-align: center;
  margin-top: 20px;
}
