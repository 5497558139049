/* Container styles for the authentication page */
.auth-container-main-wrapper {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #ffffff 50%, #f5bc00 50%);
}

/* Main container */
.auth-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 80%; /* To give some padding around */
}

/* Left section with branding */
.auth-left-container {
  flex: 1; /* Ensure it takes up available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  animation: zoomInLogo 1s ease-in-out;
}

.auth-logo-container {
  display: flex;
  justify-content: center;
  position: relative;
  right: 92px;
  bottom: 50px;
}

.auth-logo {
  width: 30em;
  animation: fadeInLogo 1s ease-in-out;
}

.dashboard-content-container {
  text-align: left;
}

.dashboard-content-container h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.dashboard-content-container p {
  font-size: 16px;
  color: #666;
  line-height: 5;
}

/* Right section with form */
.auth-right-container {
  flex: 1; /* Takes remaining space */
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  padding: 30px 40px;
  max-width: 420px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  position: relative;
  animation: zoomIn 1s ease-in-out; 
}

/* Title styling */
.auth-title {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  letter-spacing: 1px;
}


/* Form Group */
.auth-form-group {
  position: relative;
  margin-bottom: 20px;
}

/* Input Field */
.auth-input {
  width: 100%;
  padding: 12px 12px 12px 36px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.auth-input:focus {
  border-color: #f5bc00; /* Highlight border color on focus */
  box-shadow: 0 0 6px #f5bc00;
}

/* Input Icon */
.auth-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-size: 18px;
}

/* Label for Input Fields */
.auth-label {
  position: absolute;
  top: 12px;
  left: 40px;
  font-size: 14px;
  color: #333;
  transition: all 0.3s ease;
}

/* Focused/Populated Label */
.auth-input:focus ~ .auth-label,
.auth-input:not(:placeholder-shown) ~ .auth-label {
  top: -10px;
  left: 35px;
  font-size: 12px;
  color: #333;
  background-color: #fff;
  padding: 0 5px;
  border-radius: 4px;
}

/* Password Field */
.password-field {
  position: relative;
}

.auth-password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
  font-size: 20px;
  transition: color 0.3s ease;
}

.auth-password-toggle:hover {
  color: #000;
}

/* Button Styles */
.auth-btn {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  background-color: #f5bc00;
  color: #333;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  margin-bottom: 20px;
}

.auth-btn:hover {
  transform: scale(1.05);
  background-color: #ffd000;
  color: #fff;
}

/* Forgot Password Section */
.auth-forgot-password {
  text-align: right;
}

.auth-forgot-password .auth-toggle {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.auth-forgot-password .auth-toggle:hover {
  color: #f5bc00;
}

/* Switch Between Sign In/Sign Up */
.auth-switch {
  text-align: center;
  margin-top: 20px;
}

/* Social Buttons */
.auth-social-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.auth-btn-google {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.auth-btn-google:hover {
  background-color: #f1f1f1;
  transform: scale(1.05);
}

/* Checkbox Group */
.auth-checkbox-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
}

/* Remember Me Group */
.auth-form-group.remember-me-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Remember Me Checkbox */
.auth-checkbox {
  width: 18px;
  height: 18px;
  accent-color: #f5bc00;
  border-radius: 4px;
  border: 2px solid #444;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.auth-checkbox:checked {
  background-color: #f5bc00;
  border-color: #f5bc00;
}

/* Remember Me Label */
.auth-label.remember-me-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.5;
}

/* Forgot Password Link */
.auth-forgot-password {
  text-align: right;
}

.auth-forgot-password .auth-toggle {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.auth-forgot-password .auth-toggle:hover {
  color: #f5bc00;
}

/* Switch Between Sign In/Sign Up */
.auth-switch {
  text-align: center;
  margin-top: 20px;
}

/* Animations */
@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomInLogo {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive styles for mobile view */
@media (max-width: 480px) {
  .auth-container {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .auth-left-container {
    padding: 0;
    background-color: #f5f5f5;
    max-width: 100%;
  }

  .auth-right-container {
    width: 100%;
    padding: 20px;
  }

  .auth-title {
    font-size: 22px;
  }

  .auth-input {
    font-size: 14px;
    padding: 12px 14px 12px 36px;
  }

  .auth-btn {
    font-size: 15px;
    padding: 12px;
  }
}
