/* General Styling */
.product-list-page {
  font-family: "Arial", sans-serif;
  padding: 20px;
}

/* Content */
.content {
  margin-bottom: 20px;
  font-size: 16px;
  color: #6c757d;
}

.breadcrumb {
  font-size: 18px;
  font-weight: 500;
}

.breadcrumb .arrow-icon {
  margin-left: 5px;
  color: #888;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #6200ea;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6200ea;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Product List Container */
.product-list-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.product-info {
  font-size: 14px;
  color: #666;
}

.product-actions {
  display: flex;
  gap: 15px;
}

.search-input {
  padding: 8px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-btn {
  padding: 8px 16px;
  background-color: #6200ea;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.add-btn:hover {
  background-color: #7c2fe7;
}
.export-btn {
  padding: 8px 14px;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 4px;
  transition: all ease-in-out;
}

.download-icon {
  margin-left: 8px;
  font-size: 20px;
}

.export-btn:hover {
  background-color: #fcfcfc;
  transform: scale(0.95);
}

.export-btn:active {
  background-color: #00408d;
}

.export-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}



/* Table Styling */
.product-table {
  width: 100%;
  border-collapse: collapse;
}

.product-table th,
.product-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.product-table th {
  background-color: #f4f4f4;
  cursor: pointer;
}

.product-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

.action-icons {
  display: flex;
  gap: 10px;
}

.action-icon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination-btn {
  padding: 8px 16px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.pagination-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 14px;
}
