/* General Navbar Styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.navbar.dark {
  background-color: #000;
  color: #fff;
}

.search-bar-notification-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 50em;
  margin-left: 2em;
}

.search-bar {
  flex: 1;
  max-width: 50em;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.navbar .search-bar input {
  width: 100%;
  padding: 10px 50px 10px 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 14px;
  color: #444;
  background-color: #fff;
  transition: all 0.3s ease;
}

.navbar .search-bar input:focus {
  border-color: #ffb700;
  box-shadow: 0 0 5px rgba(255, 183, 0, 0.5);
  outline: none;
}

.navbar .search-bar .search-icon {
  position: absolute;
  right: 15px;
  font-size: 18px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.navbar .search-bar .search-icon:hover {
  color: #ffb700;
}

.navbar .icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar .icons .icon {
  position: relative;
  font-size: 20px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar .icons .icon:hover {
  color: #ffb700;
  transform: scale(1.1);
}
/* Notification Container */
.notification-container {
  position: relative;
  display: inline-block;
}

.notification-container .icon {
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #000;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.notification-menu {
  position: absolute;
  top: 50px;
  right: -30px;
  width: 320px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transition: visibility 0s 0.2s, opacity 0.3s ease, transform 0.2s ease;
  border: 1px solid #ddd;
}

.notification-menu::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 28px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: 0;
}

/* Dark mode styling */
.notification-menu.dark {
  background-color: #333;
  color: #fff;
  border-color: #444;
}

.notification-header {
  padding: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  color: #333;
  background-color: #f9f9f9;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.notification-header.dark {
  color: #fff;
  background-color: #222;
  border-color: #444;
}

.notification-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  gap: 15px;
  transition: background 0.3s ease, color 0.3s ease;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #ffb700;
  color: #fff;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-user-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notification-user-avatar {
  width: 40px;
  height: 40px;
  background-color: #3498db; /* Primary fallback color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.notification-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

/* Notification Content */
.notification-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.notification-message {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
}

.notification-content small {
  display: block;
  color: #777;
  font-size: 12px;
}

.notification-footer {
  text-align: center;
  padding: 16px;
  border-top: 1px solid #f1f1f1;
  background-color: #f9f9f9;
}

.notification-footer .view-all-btn {
  background-color: #ffb700;
  color: #fff;
  border: none;
  padding: 10px 18px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

.notification-footer .view-all-btn:hover {
  background-color: #e6a500;
  transform: scale(1.05);
}

.notification-footer .view-all-btn:active {
  transform: scale(0.98);
}

/* Hover effect for notification menu */
.notification-container:hover .notification-menu,
.notification-menu:hover {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0s, opacity 0.3s ease, transform 0.2s ease;
}



/* Profile Container */
.profile-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 60px;
  transition: transform 0.3s ease;
}

/* Profile Info */
.profile-info {
  display: flex;
  align-items: center;
  gap: 12px; /* Increased gap between image and text */
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.profile-info:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.profile-info img {
  width: 40px; /* Profile image size */
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd; /* Optional border around the image */
  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out; /* Smooth transition */
}

.profile-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffb94a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-info img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  border-color: #f39c12; /* Border color change on hover */
}

.profile-info span {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  transition: color 0.3s ease-in-out;
}

.profile-info:hover span {
  color: #007bff; /* Change text color on hover */
}

/* Profile Menu */
.profile-menu {
  position: absolute;
  top: 40px; 
  right: -64px; 
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  padding: 12px;
  width: 220px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-menu::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 50px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.profile-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Profile Menu Items */
.profile-menu-item {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 10px 12px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  margin-bottom: 8px;
  width: 100%;
  transition: background-color 0.3s ease-in-out, padding-left 0.3s ease-in-out;
  box-sizing: border-box; /* Prevents overlapping */
}

.profile-menu-item a {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  width: 100%;
}

.profile-menu-item a svg {
  margin-right: 12px; /* Space between icon and text */
}

.profile-menu-item:hover {
  background-color: #f4f4f4; /* Light background on hover */
  color: #007bff; /* Change text color on hover */
  padding-left: 20px; /* Subtle slide effect on hover */
}

/* Logout Button */
.profile-menu .logout-btn {
  width: 100%; /* Make the logout button span the full width */
  padding: 12px; /* Increased padding for better click area */
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  font-weight: 600;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-sizing: border-box; /* Prevent overflow */
}

.profile-menu .logout-btn:hover {
  background-color: #333; /* Darker background on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.profile-menu .logout-icon {
  font-size: 1.1em;
  margin-left: 8px;
}

.profile-menu .logout-btn:active {
  transform: scale(1.02); /* Slightly reduce size when clicked */
}

/* Accessibility Improvements */
.profile-info span {
  color: #333;
  font-weight: 500;
  white-space: nowrap;
}

.profile-info:hover + .profile-menu,
.profile-menu:hover {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

/* Additional Mobile Responsiveness */
@media (max-width: 768px) {
  .profile-info {
    gap: 8px; /* Reduce the gap on smaller screens */
  }

  .profile-info img {
    width: 32px;
    height: 32px;
  }

  .profile-menu {
    width: 180px; /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .profile-container {
    margin-right: 20px; /* Reduce margin on smaller screens */
  }

  .profile-menu {
    width: 160px;
  }

  .profile-info span {
    font-size: 12px;
  }
}
