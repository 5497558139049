.home-dashboard-wrapper-main {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.home-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px;
}

/* Header Container */
.header-container {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.header-container span {
  margin-right: 5px;
}

.header-container svg {
  color: #7e7e7e;
}

/* Cards Section */
.cards-container-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}
.goal-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #1e3a8a, #1e40af);
  color: white;
  padding: 12px 50px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.goal-summary {
  flex: 1;
  margin-left: 15px;
}

.goal-summary .goal-title {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.goal-summary p {
  font-size: 0.9rem;
}

.goal-summary .highlight {
  color: #10b981;
  font-weight: bold;
}

.goal-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ffffff20;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  border: 4px solid #10b981;
}
.metrics-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.sale-category-order-table-container {
  display: flex;
  gap: 30px;
}

.sale-category-chart-page,
.recent-orders-table-page {
  width: 100%;
  border-radius: 8px;
  padding: 20px;
}
