/* General styles */
.update-category-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.breadcrumb {
  font-size: 16px;
  color: #5f6368;
  margin-bottom: 20px;
}

.arrow-icon {
  margin-left: 10px;
  font-size: 20px;
}

/* Heading */
.page-heading h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Loading and Error States */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #6200ea; /* Purple color for the rotating part */
  border-radius: 50%; /* Make the spinner round */
  width: 40px; /* Increased size for better visibility */
  height: 40px; /* Increased size for better visibility */
  animation: spin 1.5s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-size: 16px;
  margin-top: 20px;
}

/* Form styles */
.category-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.input-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
  display: block;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  box-sizing: border-box;
}

.input-field:focus {
  outline: none;
  border-color: #6200ea;
}

select.input-field {
  padding: 10px;
}

/* Image Upload Section */
.image-upload-section {
  margin-bottom: 20px;
}

.image-upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #6200ea;
  padding: 40px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}

.remove-image-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: red;
}

.image-upload-box span {
  font-size: 16px;
  color: #333;
  margin-left: 10px;
}

.form-actions-container {
  display: flex;
  justify-content: space-between; /* Space between Cancel and Update button */
  gap: 20px;
}
/* Submit Button */
.update-category-btn {
    background: linear-gradient(
      45deg,
      #6200ea,
      #3700b3
    ); /* Gradient background */
    color: white;
    border: none;
    padding: 12px 25px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .update-category-btn:hover {
    background: linear-gradient(
      45deg,
      #3700b3,
      #6200ea
    ); /* Inverted gradient on hover */
    transform: translateY(-2px); /* Slight raise effect */
  }
  
  .update-category-btn:active {
    transform: translateY(1px); /* Pressed effect */
  }
  
/* Cancel Button */
.cancel-category-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cancel-category-btn:hover {
  background-color: #d32f2f;
  transform: translateY(-2px); /* Slight raise effect */
}

.cancel-category-btn:active {
  transform: translateY(1px); /* Pressed effect */
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .update-category-page {
    padding: 15px;
  }

  .category-form {
    padding: 15px;
  }

  .image-upload-box {
    flex-direction: column;
  }
}
