/* General styles for the page */
.category-list-page {
  padding: 20px;
}

.breadcrumb {
  font-size: 16px;
  color: #5f6368;
}

.arrow-icon {
  margin-left: 10px;
  font-size: 20px;
}

.category-list-container {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header container: flexbox layout for left and right sides */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.show-record-header h6 {
  font-size: 18px;
  color: #333;
}

.right-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Search bar design */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 300px;
}

.search-input {
  width: 100%;
  padding: 10px 40px 10px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #6200ea;
}

.search-icon {
  position: absolute;
  right: 10px;
  font-size: 18px;
  color: #6200ea;
  pointer-events: none;
}

/* Add category button */
.add-category-btn-container {
  display: flex;
  justify-content: center;
}

.add-category-btn {
  background-color: #6200ea;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-category-btn:hover {
  background-color: #3700b3;
}

/* Data table design */
.category-data-table {
  margin-top: 20px;
}

.category-image-container img {
  border-radius: 5px;
}

.actions-container {
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  background-color: #6200ea;
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #3700b3;
}

.delete-button {
  background-color: #f44336;
}

.delete-button:hover {
  background-color: #d32f2f;
}

/* Spinner for loading state */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6200ea;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: red;
  margin-top: 20px;
  font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .right-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-container {
    width: 100%;
  }

  .add-category-btn-container {
    margin-top: 10px;
  }
}
